<script setup>
    import { ref, inject, computed } from 'vue';
    import { InjectionKeys } from 'o365-utils';

    const { pwaStoreKey } = InjectionKeys;

    const { state: offlineState } = inject(pwaStoreKey);

    const props = defineProps(['pwaState', 'toggleAccordion']);

    const pwaState = ref(props.pwaState);

    const appInstalled = computed(() => {
        return pwaState.value?.isAppInstalled ?? null;
    });
    const appHasUpdate = computed(() => {
        return pwaState.value?.hasAppUpdateAvailable ?? null;
    });

    const appStatusClass = computed(() => {
        if (appInstalled.value === null && appHasUpdate.value === null) {
            return 'bi-arrow-repeat text-danger';
        }

        if (!appInstalled.value || appHasUpdate.value) {
            return 'bi-arrow-repeat text-primary';
        }
        
        return 'bi-arrow-repeat text-success';
    });

    const hasDatabaseConnection = computed(() => {
        return offlineState?.pwaState?.hasDatabaseConnection ?? false;
    });
</script>

<template>
    <div class="d-flex gap-2 bg-light rounded shadow-sm py-1 px-3" style="cursor:pointer;" @click="toggleAccordion">
        <i class="bi" :class="appStatusClass"></i>

        <span class="border-start mx-1"></span>
        
        
    </div>
</template>